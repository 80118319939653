// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyDLlGRUgtwiYV6GtuMGa6UXvziideQo-mI",
    authDomain: "sharetime-b91e4.firebaseapp.com",
    databaseURL: "https://sharetime-b91e4.firebaseio.com",
    projectId: "sharetime-b91e4",
    storageBucket: "sharetime-b91e4.appspot.com",
    messagingSenderId: "88899959320",
    appId: "1:88899959320:web:6b6a72268069d2e01fa16d",
    measurementId: "G-GK9PC7Q8HR"
  },
  facebookAppId:"924400988053886",
  googleCLientId:"88899959320-cb4t81ekt6l1fe4sgmuq0a1m6212k62r.apps.googleusercontent.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
