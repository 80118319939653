import { TimePost } from "./timepost.interface";
import firebase from 'firebase/app';

export enum AppointmentStatus{
  CREATED = 1,
  COMPLETE = 2
}
export interface Appointment {
  between?:any;
  id?:string;
  uids?: string[];
  chatId?: string;
  postId?: string;
  date?:firebase.firestore.Timestamp | Date;
  timeStamp?: number;
  post?: TimePost;
  duration?: number;
  uid?:string;
  status?:AppointmentStatus;
}

export interface EventSource {
  title: string;
  startTime: Date;
  endTime: Date;
  allDay: boolean;
}
