import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { UserModel } from "../interfaces/userModel.interface";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { BehaviorSubject, Observable, of, pipe } from "rxjs";
import { Router } from "@angular/router";
import { first, map, switchMap } from "rxjs/operators";
import * as firebase from "firebase/app";
import { LoadingController, ModalController, Platform } from "@ionic/angular";
import { CompleteAppointmentPage } from "../pages/complete-appointment/complete-appointment.page";

import * as moment from "moment";
import { Collection } from "../enums/Collections.enum";
import { Thanks, ThanksStatus } from "../interfaces/Thanks.interface";
import { GiveThanxAlertComponent } from "../pages/thanks-prompt/give-thanx-alert/give-thanx-alert.component";
import { UtilService } from "./util.service";
import { NotificationsService } from "./notifications.service";
import { TimePostStatus } from "../interfaces/timepost.interface";
import { AppointmentStatus } from "../interfaces/Appointment.interface";
import { Plugins } from "@capacitor/core";
import {
  SignInWithApple,
  AppleSignInResponse,
  AppleSignInErrorResponse,
  ASAuthorizationAppleIDRequest,
} from "@ionic-native/sign-in-with-apple/ngx";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  registerForm;
  $User: Observable<any>;
  currentUser;
  user: UserModel;
  $email: BehaviorSubject<string> = new BehaviorSubject("");
  authLoading: boolean = false;
  registering: boolean = false;

  constructor(
    private _auth: AngularFireAuth,
    private _afs: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private modal: ModalController,
    private util: UtilService,
    private platform: Platform,
    private notificationService: NotificationsService,
    private loader: LoadingController,
    private signInWithApple: SignInWithApple
  ) {
    this.platform.resume.subscribe((e) => {
      if (this.user) {
        this.checkCompletePosts(this.user.uid).then((result) => {
          if (result && Array.isArray(result) && result.length) {
            this.completeAppointmentModal(result);
          }
        });
        this.checkThanks(this.user.uid);
      }
    });

    this.$User = this._auth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this._afs.doc<any>(`Users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
    this.$User.subscribe(
      (user) => {
        this.authLoading = true;
        this.user = user;
        this.util.dismissLoader();
        if (user) {
          this.notificationService.initNotifications(user.uid);

          if(this.user.blockedCount >= 5){
            this.router.navigate(["blocked"]);
          } else if(!this.registering) {
            this.router.navigate(["main", "posts"]);
          }
          
          this.checkCompletePosts(user.uid).then((result) => {
            if (result && Array.isArray(result) && result.length) {
              this.completeAppointmentModal(result);
            }
          });
          this.checkThanks(user.uid);
        }
        this.authLoading = false;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  authenticated() {
    return this._auth.currentUser;
  }

  async getAuthState() {
    return this._auth.authState.toPromise();
  }

  async registerPushToken(token: string) {
    localStorage.setItem("PushToken", token);
    let existingToken = await this._afs
      .collection(Collection.PUSHTOKEN, (ref) =>
        ref.where("token", "==", token)
      )
      .get()
      .toPromise();

    let existing =
      existingToken &&
      Array.isArray(existingToken.docs) &&
      existingToken.docs.length > 0
        ? existingToken.docs[0].data()["token"]
        : undefined;
    if (!existing) {
      let createId = this._afs.createId();
      return this._afs
        .collection(Collection.PUSHTOKEN)
        .doc(createId)
        .set({ uid: this.user.uid, token: token });
    } else {
      return Promise.resolve();
    }
  }

  async deregisterToken() {
    const token = localStorage.getItem("PushToken");
    console.log("Token -", token);
    if (!token) return Promise.resolve();

    let existingToken = await this._afs
      .collection(Collection.PUSHTOKEN, (ref) =>
        ref.where("token", "==", token)
      )
      .get()
      .toPromise();
    let existing =
      existingToken &&
      Array.isArray(existingToken.docs) &&
      existingToken.docs.length > 0
        ? existingToken.docs[0].data()["token"]
        : undefined;
    console.log(existing);
    if (Array.isArray(existingToken.docs) && existingToken.docs.length > 0) {
      console.log(
        "TOken exists --->",
        JSON.stringify(existingToken.docs[0].data())
      );
      return this._afs
        .collection(Collection.PUSHTOKEN)
        .doc(existingToken.docs[0].id)
        .delete();
    } else {
      return Promise.resolve();
    }
  }

  checkThanks(uid: string) {
    this._afs
      .collection(Collection.THANKS, (ref) => ref.where("uid", "==", uid))
      .valueChanges()
      .pipe(first())
      .subscribe(async (thanks: Thanks[]) => {
        const filtered = thanks.filter((t) => t.status == ThanksStatus.CREATED);
        if (filtered.length > 0) {
          this.modal.dismiss();
          let modal = await this.modal.create({
            component: GiveThanxAlertComponent,
            componentProps: { thanks: filtered[0] },
          });
          modal.present();
        }
      });
  }

  async checkCompletePosts(uid: string) {
    let notComplete;
    try {
      notComplete = await this.completePostsPromise(uid);
    } catch (err) {
      console.log("err", err);
    }
    const data = notComplete.docs;
    if (!data) {
      return null;
    }
    return data.filter((i) => {
      const endDate = moment(new Date(i.get("date").seconds * 1000))
        .utc()
        .add(i.get("duration"), "hour");
      return endDate < moment().utc();
    });
  }

  completePostsPromise(uid: string) {
    return new Promise((resolve, reject) => {
      this._afs
        .collection(Collection.APPOINTMENTS, (ref) => {
          let query:
            | firebase.firestore.CollectionReference
            | firebase.firestore.Query = ref;
          query = query.where("uid", "==", uid);
          query = query.where("status", "==", AppointmentStatus.CREATED);
          return query;
        })
        .get()
        .pipe(first())
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  async completeAppointmentModal(appointments) {
    this.modal.dismiss();
    let create = await this.modal.create({
      component: CompleteAppointmentPage,
      componentProps: { appointments },
    });
    create.present();
  }

  login(email: string, password: string) {
    return this._auth.signInWithEmailAndPassword(email, password);
  }

  // async setDbUser(email:string){
  //   let User = null;
  //   try{
  //    User = await this.findUserByEmail(email);
  //   }catch(err){
  //     console.log("Could not find User",err);
  //     throw "Not Registered"
  //   };
  //   this.$User.next(User);
  //   return User;
  // }

  createUser(id: string, model: UserModel) {
    const obj = JSON.parse(JSON.stringify(Object.assign({}, model)));
    return this._afs.collection("Users").doc(id).set(obj);
  }

  updateUser(model: UserModel) {
    const obj = JSON.parse(JSON.stringify(Object.assign({}, model)));
    return this._afs
      .collection("Users")
      .doc(model.uid)
      .set(obj, { merge: true });
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  uploadPhoto(uid: string, photo: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const filename = `${this._afs.createId()}_${new Date().toISOString()}.jpg`;
      const path = "User_pictures/" + uid + "/" + filename;
      const base64 = photo.split("base64,")[1];
      console.log("uploading file");

      await this.storage
        .ref(path)
        .putString(base64, "base64", { contentType: "image/jpeg" });
      let url = photo;
      console.log("done upload");
      try {
        console.log("try getDownloadURL");
        url = await this.storage.ref(path).getDownloadURL().toPromise();
      } catch (err) {
        // url = await this.storage.ref(path).getDownloadURL().toPromise();
        console.log("err in downloadUrl", err);
        return reject(err);
      }

      return resolve(url);
      // let code = this._afs.createId();
      // let date = new Date().toISOString()
      // let upload = await this.storage.upload(`${uid}/pic_${code}_${date}`,photo);
      // return upload.downloadURL;
    });
  }

  async registerUser(model: UserModel, password: string) {
    return this._auth
      .createUserWithEmailAndPassword(model.email, password)
      .then(async (User) => {
        model.uid = User.user.uid;
        // try {
        //   model.selfie = await this.uploadPhoto(model.uid, model.selfie);
        // } catch (err) {
        //   await User.user.delete();
        //   throw "Photo upload failed";
        // }

        try {
          await this.createUser(User.user.uid, model);
        } catch (err) {
          await User.user.delete();
          throw "User could not be created";
        }
        return User.user.sendEmailVerification();
      })
      .catch((err) => {
        throw err;
      });
  }

  randomPw() {
    var pass = "";
    var str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

    for (let i = 1; i <= 8; i++) {
      var char = Math.floor(Math.random() * str.length + 1);

      pass += str.charAt(char);
    }

    return pass;
  }

  async registerAndLinkUser(email: string, accessToken: string) {
    let Auth = await this._auth.createUserWithEmailAndPassword(
      email,
      this.randomPw()
    );
    var facebookProvider =
      firebase.auth.FacebookAuthProvider.credential(accessToken);
    await Auth.user.linkWithCredential(facebookProvider);
    return this._auth.signInWithCredential(facebookProvider);
  }

  signInWithCredential(type: "facebook" | "apple" | "google", token: any) {
    let credential;
    if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(token);
    } else if (type === "apple") {
      const provider = new firebase.auth.OAuthProvider("apple.com");
      credential = provider.credential({
        idToken: token.identityToken,
      });
    }
    console.log(credential);
    return this._auth.signInWithCredential(credential);
  }

  async linkUser(accessToken: string) {
    var facebookProvider =
      firebase.auth.FacebookAuthProvider.credential(accessToken);
    return (await this._auth.currentUser).linkWithCredential(facebookProvider);
  }

  findUserByEmail(email: string): Promise<UserModel | null> {
    return new Promise((resolve) => {
      let query = this._afs.collection("Users", (ref) =>
        ref.where("email", "==", email).limit(1)
      );
      query
        .valueChanges()
        .pipe(first())
        .subscribe((user) => {
          resolve(user && Array.isArray(user) ? user[0] : null);
        });
    });
  }

  UserObservable(email: string): Observable<UserModel | null> {
    let query = this._afs.collection("Users", (ref) =>
      ref.where("email", "==", email).limit(1)
    );
    return query
      .valueChanges()
      .pipe(map((user) => (user && Array.isArray(user) ? user[0] : null)));
  }

  async signOut() {
    try {
      let loader = await this.loader.create({ message: "Logging Out..." });
      loader.present();
      this.deregisterToken()
        .then(() => {
          return this._auth.signOut();
        })
        .then(async () => {
          this.router.navigate(["login"]);
          localStorage.clear();
          this.user = null;
          this.$User = of(null);
          this.notificationService.$myNotifications = of([]);
          this.currentUser = undefined;
          try {
            Plugins.FacebookLogin.logout();
          } catch (err) {}
          loader.dismiss();
        })
        .catch(async (err) => {
          this.router.navigate(["login"]);
          console.log("Dereg Err", JSON.stringify(err));
          localStorage.clear();
          this.user = null;
          this.$User = this.currentUser = undefined;
          this._auth.signOut();
          loader.dismiss();
        });
    } catch (err) {
      this.user = null;
      this.$User = of(null);
      this._auth.signOut();
      this.router.navigate(["login"]);
      this.loader.dismiss();
    }
  }

  forgotPassword(email: string) {
    return this._auth.sendPasswordResetEmail(email);
  }

  async AppleSignIn() {
    this.signInWithApple
      .signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
        ],
      })
      .then(async (res: AppleSignInResponse) => {
        const credential = new firebase.auth.OAuthProvider(
          "apple.com"
        ).credential(res.identityToken);
        const response = await firebase.auth().signInWithCredential(credential);
        let existingUser = await this.findUserByEmail(response.user.email);

        if (existingUser) {
          this.$User = this._auth.authState.pipe(
            switchMap((user) => {
              if (user) {
                return this._afs.doc<any>(`Users/${user.uid}`).valueChanges();
              } else {
                return of(null);
              }
            })
          );

          if(this.user.blockedCount >= 5){
            this.router.navigate(["blocked"]);
          } else {
            this.router.navigate(["main", "posts"]);
          }
          
        } else {
          let name = "";
          try {
            name =
              res.fullName?.familyName ||
              res.fullName?.givenName ||
              res.fullName?.nickname ||
              response.user.displayName;
          } catch (err) {}
          let queryParams = {
            type: "apple",
            name: name || "",
            email: response.user.email,
            picture: response.user.photoURL,
            uid: response.user.uid,
          };
          this.router.navigate(["register"], { queryParams });
        }
      })
      .catch((err) => {
        console.log("credentials fetch failed" + JSON.stringify(err));
      });
  }

  getUserDataById(id:string){
    return this._afs.collection("Users").doc(id).valueChanges();
  }
}
