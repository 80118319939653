import firebase from "firebase/app";
import { PostType } from "../enums/postType.enum";
import { GeoLocation, UserModel } from "./userModel.interface";

export enum TimePostStatus {
  ACTIVE = "a",
  REQUESTED = "r",
  COMPLETE = "c",
  DRAFT = "d",
  LIVE = "l",
  ARCHIVE = "arc",
  INPROGRESS = "ip",
  SCHEDULED = "s",
}
export interface TimePost {
  id?: string;
  uid?: string;
  status?: TimePostStatus;
  type?: PostType;
  title?: string;
  category?: string;
  labels?: string[];
  description?: string;
  date?: any | firebase.firestore.Timestamp | Date;
  area?: string;
  toArea?: string;
  geotag?: GeoLocation;
  geoHash?: any;
  radius?: number;
  maxRequests?: number;
  shareable?: boolean;
  likes?: number;
  live?: boolean;
  color?: string;
  avatar?: string;
  requests?: number;
  completedDate?: any | firebase.firestore.Timestamp | Date;
  user?: UserModel;
  media?: string[];
}

export class initTimePost {
  id?: string;
  uid?: string;
  status?: TimePostStatus;
  type?: PostType;
  title?: string;
  category?: string;
  labels?: string[];
  description?: string;
  date?: firebase.firestore.Timestamp | Date;
  area?: string;
  geotag?: GeoLocation;
  radius?: number;
  maxRequests?: number;
  requests?: number;
  shareable?: boolean;
  likes?: number;
  live?: boolean;
  color?: string;
  avatar?: string;
  media?: string[];
  constructor(uid: string, type: string | number) {
    this.uid = uid;
    this.status = TimePostStatus.ACTIVE;
    this.type = parseInt(type as string);
    this.title = "";
    this.category = "";
    this.labels = [];
    this.description = "";
    this.date = new Date();
    this.area = "";
    this.radius = 50;
    this.maxRequests = 10;
    this.shareable = true;
    this.likes = 0;
    this.live = true;
    this.color = "";
    this.avatar = "";
    this.requests = 0;
    this.media = [];
  }
}
