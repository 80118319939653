export enum Collection {
  CHAT = "Chat",
  USERS = "Users",
  NOTIFICATIONS = "Notifications",
  POSTS = "TimePosts",
  Requests = "Requests",
  APPOINTMENTS = "Appointments",
  THANKS = "Thanks",
  PUSHTOKEN = "PushTokens",
  FEEDBACK = "Feedback"
}
