import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { PostsService } from '../../services/posts.service';
import * as moment from 'moment';
import { TimePost, TimePostStatus } from '../../interfaces/timepost.interface';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Collection } from '../../enums/Collections.enum';
import { AngularFirestore } from '@angular/fire/firestore';
import { Appointment, AppointmentStatus } from '../../interfaces/Appointment.interface';
import { filter, takeUntil } from 'rxjs/operators';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ThanksStatus } from '../../interfaces/Thanks.interface';

@Component({
  selector: 'st-complete-appointment',
  templateUrl: './complete-appointment.page.html',
  styleUrls: ['./complete-appointment.page.scss'],
})
export class CompleteAppointmentPage implements OnInit {
  postsToComplete:TimePost[];
  appointmentsObject:any = {};
  $destroy:Subject<void> = new Subject();
  faSpinner = faSpinner;
  constructor(private modal:ModalController,private navParams:NavParams,private _afs:AngularFirestore) { }
  ngOnInit() {
    const appoint = this.navParams.get('appointments');
    appoint.map(i=>{
      const postId = i.get('postId');
      const endDate = moment(new Date(i.get('date').seconds * 1000)).add(i.get('duration'),'hour').format('YYYY-MM-DD @ HH:mm')
      const appointmentId = i.get('id');
      const completing = false;
      const between = i.get('between');
      this.appointmentsObject[postId] = {endDate,postId,appointmentId,completing,between};
    });

    
    
    
    this.getPostsByIds(Object.keys(this.appointmentsObject))
    .pipe(takeUntil(this.$destroy)).subscribe((posts)=>{
      this.postsToComplete = posts.filter(i=>i.status!==TimePostStatus.COMPLETE);
      if(this.postsToComplete.length==0){
        this.close();
      }
    })
  }
  getPostsByIds(postIds){
    const posts:Observable<TimePost>[] = postIds.map(id=>this._afs.collection(Collection.POSTS).doc(id).valueChanges())
    return combineLatest(posts)
  }
 
  close(){
    this.modal.dismiss();
    this.$destroy.complete();
    this.$destroy.next();
  }


  async complete(post:TimePost){
    this.appointmentsObject[post.id].completing = true;
      return this._afs.collection(Collection.APPOINTMENTS)
      .doc(this.appointmentsObject[post.id].appointmentId)
      .update({status:AppointmentStatus.COMPLETE})
      .then(()=>{
        return this._afs.collection(Collection.POSTS).doc(post.id).update({status:TimePostStatus.COMPLETE})
      })
      .then(()=>{
        const id = this._afs.createId();
        let [toUid] = this.appointmentsObject[post.id].between.filter(i=>i!==post.uid);
        return this._afs.collection(Collection.THANKS).doc(id).set({
          id:id,
          postId:post.id,
          toUid:post.uid,
          uid:toUid,
          status:ThanksStatus.CREATED,
          timeStamp:new Date()
        })
      })
      .then(()=>{
        const index = this.postsToComplete.findIndex((i)=>i.id===post.id);
        if(index > -1){
          this.postsToComplete.splice(index,1);
        }
        this.appointmentsObject[post.id].completing = false;
      })
  }

}
