import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdNumberValidator } from './id-number.directive';
import { ProfilePicDirective } from './profile-pic.directive';
import { LongPressDirective } from './long-press.directive';



@NgModule({
  declarations: [IdNumberValidator, ProfilePicDirective, LongPressDirective],
  imports: [
    CommonModule
  ],
  exports:[IdNumberValidator,ProfilePicDirective,LongPressDirective]
})
export class DirectiveModule { }
