import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[stProfilePic]'
})
export class ProfilePicDirective implements OnChanges{
  @Input() stProfilePic;
  constructor(private el:ElementRef,private renderer:Renderer2) { }
  ngOnChanges(){
    this.el.nativeElement.src = this.stProfilePic;
    let width = this.el.nativeElement.naturalWidth;
    let height = this.el.nativeElement.naturalHeight;
    const ratio = width / height;
    const newWidth = 50;
    const newHeight = (newWidth / ratio).toFixed(0);
    // this.renderer.setStyle(this.el.nativeElement,'border-radius','50%');
    this.renderer.setAttribute(this.el.nativeElement,'width',newWidth+'px');
    this.renderer.setAttribute(this.el.nativeElement,'height',newHeight+'px');
  }

}
