import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, of } from "rxjs";
import { Notification } from "../interfaces/notifications.interface";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  existingNotifications: any;
  $myNotifications: Observable<any[]> = of([]);
  constructor(private _afs: AngularFirestore) {}

  initNotifications(uid: string) {
    this.$myNotifications = this.getYourNotifications(uid);
  }

  async createNotification(notification: Notification, uid) {
    //console.log(notification.notifications[0]);
    //console.log(uid);
    try {
      this.existingNotifications = await this._afs
        .collection("Notifications")
        .doc(uid)
        .valueChanges()
        .toPromise();
    } catch (err) {}

    if (this.existingNotifications) {
      //console.log('Document does exist');
      this.existingNotifications.notifications.push(
        notification.notifications[0]
      );
      this._afs
        .collection("Notifications")
        .doc(uid)
        .update(this.existingNotifications);
    } else {
      //console.log('Document does not exist');
      this._afs.collection("Notifications").doc(uid).set(notification);
    }
  }

  getYourNotifications(uid: string) {
    return this._afs
      .collection("Notifications", (ref) => ref.where("uid", "==", uid))
      .valueChanges();
  }

  readNotification(id: string) {
    return this._afs
      .collection("Notifications")
      .doc(id)
      .set({ read: true }, { merge: true });
  }
}
