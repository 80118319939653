import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActionSheetController, NavController } from "@ionic/angular";
import { faHeart, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Thanks, ThanksStatus } from "../../../interfaces/Thanks.interface";
import { Observable, Subject } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { Collection } from "../../../enums/Collections.enum";
import { takeUntil } from "rxjs/operators";
import { TimePost } from "../../../interfaces/timepost.interface";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { AngularFireStorage } from "@angular/fire/storage";

@Component({
  selector: "st-give-thanx",
  templateUrl: "./give-thanx.component.html",
  styleUrls: ["./give-thanx.component.scss"],
})
export class GiveThanxComponent implements OnInit {
  faHeart = faHeart;
  numberOfHearts: number[] = [1, 2, 3, 4, 5];
  faSpinner = faSpinner;
  @Input() thanksId: string;
  @Output() back: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  thanks: Thanks;
  $thanks: Observable<Thanks>;
  $destroy: Subject<void> = new Subject();
  saving: boolean = false;
  captured: boolean = false;
  constructor(
    private _afs: AngularFirestore,
    private storage: AngularFireStorage,
    private camera: Camera,
    private action: ActionSheetController
  ) {}

  takePicture(type: "library" | "camera") {
    let options: CameraOptions = {
      quality: 20,
      targetWidth: 1000,
      targetHeight: 1000,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.PNG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType:
        type === "camera"
          ? this.camera.PictureSourceType.CAMERA
          : this.camera.PictureSourceType.PHOTOLIBRARY,
    };

    this.camera
      .getPicture(options)
      .then((base64Image) => {
        console.log("base64Image", base64Image.substr(0, 10));
        this.thanks.media.push("data:image/jpeg;base64," + base64Image);
        this.captured = true;
      })
      .catch(() => {
        this.captured = false;
        // this.util.showToast('Photo Capture Canceled');
      });
  }

  async uploadMedia() {
    let actionSheet = await this.action.create({
      buttons: [
        {
          text: "Camera",
          icon: "camera",
          handler: () => {
            this.takePicture("camera");
          },
        },
        {
          text: "Photo Library",
          icon: "images",
          handler: () => {
            this.takePicture("library");
          },
        },
        {
          text: "Cancel",
          role: "cancel",
          icon: "undo",
          handler: () => {
            console.log("Cancel clicked");
          },
        },
      ],
    });
    actionSheet.present();
  }

  closeModal() {
    this.close.emit();
  }

  initThanks(): Thanks {
    return {
      id: this.thanksId,
      message: "",
      media: [],
      rating: 0,
      status: ThanksStatus.THANKED,
    };
  }
  ngOnInit() {
    // this._afs.collection(Collection.THANKS).doc(this.thanksId)
    // .valueChanges()
    // .pipe(takeUntil(this.$destroy))
    // .subscribe((thanks)=>{
    //   console.log(thanks);
    // });

    //To set the number of selected heart
    // this.setHeartRate(3);
    this.thanks = this.initThanks();
  }

  setHeartRate(rating) {
    this.thanks.rating = rating;
  }

  goBack() {
    this.back.emit(false);
    this.$destroy.complete();
    this.$destroy.next();
  }

  uploadPhoto(postId: string, photo: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const filename = `${this._afs.createId()}_${new Date().toISOString()}.jpg`;
      const path = "Thanks_media/" + postId + "/" + filename;
      const base64 = photo.split("base64,")[1];
      console.log("uploading file");

      await this.storage
        .ref(path)
        .putString(base64, "base64", { contentType: "image/jpeg" });
      let url = photo;
      console.log("done upload");
      try {
        console.log("try getDownloadURL");
        url = await this.storage.ref(path).getDownloadURL().toPromise();
      } catch (err) {
        // url = await this.storage.ref(path).getDownloadURL().toPromise();
        console.log("err in downloadUrl", err);
        return reject(err);
      }

      return resolve(url);
    });
  }

  confirmThanks() {
    if (!this.thanks.message.trim()) {
      return;
    }
    this.saving = true;
    let uploads = [];
    for (let photo of this.thanks.media) {
      uploads.push(this.uploadPhoto(this.thanks.postId, photo));
    }
    this.thanks.media = [];
    Promise.all(uploads)
      .then((results) => {
        this.thanks.media = results.map((r) => r);
        return this.thanks.media;
      })
      .then(() => {
        return this._afs
          .collection(Collection.THANKS)
          .doc(this.thanksId)
          .update(this.thanks);
      })
      .then((result) => {
        this.saving = false;
        this.closeModal();
      })
      .catch((err) => {
        this.saving = false;
      });
  }
}
