import { NgModule } from "@angular/core";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { HttpClientModule } from "@angular/common/http";
import { Facebook } from "@ionic-native/facebook/ngx";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SharedModule } from "./shared/shared.module";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { IonicGestureConfig } from "./gestures/ionic-gesture-config";
import { CompleteAppointmentPageModule } from "./pages/complete-appointment/complete-appointment.module";
import { ThanksPromptModule } from "./pages/thanks-prompt/thanks-prompt.module";
import { FormsModule } from "@angular/forms";
import { SignInWithApple } from "@ionic-native/sign-in-with-apple/ngx";
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    FormsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    GooglePlaceModule,
    HttpClientModule,
    FontAwesomeModule,
    CompleteAppointmentPageModule,
    ThanksPromptModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    Facebook,
    GooglePlus,
    SignInWithApple
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
