

import {  
  NG_VALIDATORS,
  FormControl,  
  ValidatorFn,  
  Validator  
 } from '@angular/forms';  
 import { Directive } from '@angular/core'; 
import { isNull } from 'util';
function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function validIdNumber(idNumber:number) {

  if(isNull(idNumber) || typeof idNumber === "undefined") return false;

  //Ref: http://www.sadev.co.za/content/what-south-african-id-number-made
  // SA ID Number have to be 13 digits, so check the length
  if (idNumber && idNumber.toString().length != 13 || !isNumber(idNumber)) return false;

  // get first 6 digits as a valid date
  const year = parseInt(idNumber.toString().substring(0, 2));
  const month = parseInt(idNumber.toString().substring(2, 4)) - 1
  const day = parseInt(idNumber.toString().substring(4, 6))
  var tempDate = new Date(year, month,day);

  var id_date = tempDate.getDate();
  var id_month = tempDate.getMonth();
  var id_year = tempDate.getFullYear();

  // var fullDate = id_date + "-" + id_month + 1 + "-" + id_year;

  if (!(parseInt(tempDate.getFullYear().toString().substr(-2)) == year && id_month == month && id_date == day)) {
    return false;
  }

  // get the gender
  var genderCode = idNumber.toString().substring(6, 10);
  var gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

  // get country ID for citzenship
  var citzenship = parseInt(idNumber.toString().substring(10, 11)) == 0 ? "Yes" : "No";

  // apply Luhn formula for check-digits
  var tempTotal = 0;
  var checkSum = 0;
  var multiplier = 1;
  for (var i = 0; i < 13; ++i) {
      tempTotal = parseInt(idNumber.toString().charAt(i)) * multiplier;
      if (tempTotal > 9) {
          tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
      }
      checkSum = checkSum + tempTotal;
      multiplier = (multiplier % 2 == 0) ? 1 : 2;
  }
  if ((checkSum % 10) != 0) {
    return false;
  };


 return true;
}


@Directive({  
  selector: '[idNumberValidator][ngModel]',  
  providers: [  
   {  
    provide: NG_VALIDATORS,  
    useExisting: IdNumberValidator,  
    multi: true  
   }  
  ]  
 })  
 export class IdNumberValidator implements Validator {  
  validator: ValidatorFn;  
  constructor() {  
   this.validator = this.idValidator();
  }  

  // @HostListener("input", ["$event.type", "$event.target.value"])
  // oninput(event: string, value: string): void {
  //   let newValue = value.toLowerCase().trim().replace(/ /g, '');
  //   this.renderer.setProperty(this.elref.nativeElement, "value", newValue);
  //   this.validator = this.emailValidator();
  // }

  // @HostListener("blur", ["$event.type", "$event.target.value"])
  // onBlur(event: string, value: string): void {
  //   let newValue = value.toLowerCase().trim().replace(/ /g, '');
  //   this.renderer.setProperty(this.elref.nativeElement, "value", newValue);
  //   this.validator = this.emailValidator();
  // }

  validate(c: FormControl) {  
   return this.validator(c);  
  }  
 
 idValidator(): ValidatorFn {  
   return (c: FormControl) => {  
    let isValid = validIdNumber(c.value);
    if (isValid) {  
     return null;  
    } else {  
     return {  
      idNumberValidate: {  
       valid: false  
      }  
     };  
    }  
   }  
  }  
 }