import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import {
  map,
  shareReplay,
  switchMap,
  filter,
  publishReplay,
} from "rxjs/operators";
import { PostType } from "../enums/postType.enum";
import { TimePost, TimePostStatus } from "../interfaces/timepost.interface";
import * as firebase from "firebase/app";
import { PostRequest } from "../interfaces/PostRequest.interface";
import { leftJoinDocument } from "../operators/joins";
import { Collection } from "../enums/Collections.enum";
import { AuthService } from "./auth.service";
import { Coordinates } from "@ionic-native/geolocation/ngx";

import * as geofirex from "geofirex";
import { UserModel } from "../interfaces/userModel.interface";
import { AngularFireStorage } from "@angular/fire/storage";
import { PostReport } from "../components/report-post/report-post.component";
const geo = geofirex.init(firebase);

@Injectable({
  providedIn: "root",
})
export class PostsService {
  searchRadius: BehaviorSubject<number> = new BehaviorSubject(
    localStorage.getItem("radius")
      ? parseInt(localStorage.getItem("radius"))
      : 2000
  );
  constructor(
    private _afs: AngularFirestore,
    private auth: AuthService,
    private storage: AngularFireStorage
  ) {}

  reportPost(report: PostReport) {
    report.id = this._afs.createId();
    return this._afs.collection("PostReports").doc(report.id).set(report);
  }

  getBlockedCount(userId:string){
    return this._afs.collection("Users").doc(userId).valueChanges();
  }

  blockUser(userId:string, count:Number) {
    return this._afs.collection("Users").doc(userId).update({blockedCount: count});
  }

  myBlockedUsers(userId:string, blockId:string) {
    return this._afs.collection("Users").doc(userId).update({blockedUsers: [{ who: blockId, when: new Date() }]});
  }

  getPostsByIds(postIds: string[]) {
    return this._afs
      .collection(Collection.POSTS, (ref) => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;
        for (let id of postIds) {
          query = query.where("id", "==", id);
        }
        return query;
      })
      .valueChanges();
  }

  async createOrEditPost(post: TimePost) {
    if (!post.id) {
      post.id = this._afs.createId();
    }

    let uploads = [];
    for (let photo of post.media) {
      uploads.push(this.uploadPostPhoto(post.id, photo));
    }

    post.media = [];
    return Promise.all(uploads)
      .then((photos) => {
        post.media = photos.map((r) => r);
        return post.media;
      })
      .then((media) => {
        post.media = media;
        return this._afs
          .collection("TimePosts")
          .doc(post.id)
          .set(Object.assign({}, post));
      });
  }

  uploadPostPhoto(postId: string, photo: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      if (photo.startsWith("https://")) {
        return resolve(photo);
      }

      const filename = `${this._afs.createId()}_${new Date().toISOString()}.jpg`;
      const path = "Post_media/" + postId + "/" + filename;
      const base64 = photo.split("base64,")[1];

      await this.storage
        .ref(path)
        .putString(base64, "base64", { contentType: "image/jpeg" });
      let url = photo;
      console.log("done upload");
      try {
        console.log("try getDownloadURL");
        url = await this.storage.ref(path).getDownloadURL().toPromise();
      } catch (err) {
        // url = await this.storage.ref(path).getDownloadURL().toPromise();
        console.log("err in downloadUrl", err);
        return reject(err);
      }
      console.log("upload complete");
      return resolve(url);
    });
  }

  getYourPosts(uid: string) {
    return this._afs
      .collection("TimePosts", (ref) => ref.where("uid", "==", uid))
      .valueChanges();
  }

  getPost(postId: string) {
    return this._afs.collection(Collection.POSTS).doc(postId).valueChanges();
  }

  getPosts(geoLocation: Coordinates, type: PostType) {
    // const posts = firebase.firestore().collection('TimePosts',(ref) => {

    //   let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    //   query = query.where("status", "==", TimePostStatus.ACTIVE);
    //   query = query.where("type", "==", parseInt(type as any));
    //   query = query.where("live", "==", true);
    //   query = query.where("date", ">", timestamp);
    //   return query;
    // });
    // let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    let query: any = firebase.firestore().collection("TimePosts");
    query = query.where("status", "==", TimePostStatus.ACTIVE);
    query = query.where("type", "==", parseInt(type as any));
    query = query.where("live", "==", true);
    // query = query.where("date", ">", timestamp);

    const center = geo.point(geoLocation.latitude, geoLocation.longitude);
    const radius = this.searchRadius.getValue() || 2000;
    const field = "geoHash";
    return geo
      .query(query)
      .within(center, radius, field, { units: "km" })
      .pipe(
        map((p: any[]) => {
          return p.filter(
            (i) =>
              i.uid != this.auth.user.uid &&
              i.date.seconds * 1000 > new Date().getTime()
          );
        })
      );
    // return this._afs
    //   .collection("TimePosts",
    // (ref) => {
    //     let query:firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    //     let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    //     query = query.where("status", "==", TimePostStatus.ACTIVE);
    //     query = query.where("type", "==", parseInt(type as any));
    //     query = query.where("live", "==", true);
    //     query = query.where("date", ">", timestamp);

    //     // query = query.where("geoPoint",'')
    //     return query;
    //   })
    //   .valueChanges()
  }

  postById(id: string): Observable<TimePost> {
    // return this._afs.collection('TimePosts',ref => ref.where('id', '==', id)).valueChanges();
    return this._afs.collection("TimePosts").doc(id).valueChanges();
  }

  getRequests(postId: string): Observable<PostRequest[]> {
    return this._afs
      .collection<PostRequest>(Collection.Requests, (ref) =>
        ref.where("postId", "==", postId)
      )
      .valueChanges()
      .pipe(
        switchMap((requests: PostRequest[]) => {
          const users = requests.map((i) => i.uid);

          return combineLatest([
            of(requests),
            combineLatest(
              users.map((uid) =>
                this._afs
                  .collection<UserModel>(Collection.USERS)
                  .doc(uid)
                  .valueChanges()
              )
            ),
          ]);
        }),
        map(([Requests, Users]) => {
          return Requests.map((req: PostRequest) => {
            req.user = Users.find((a) => a["uid"] === req.uid);
            return req;
          });
        })
      );
  }

  getRequestsOnly(postId: string) {
    return this._afs
      .collection(Collection.Requests, (ref) =>
        ref.where("postId", "==", postId)
      )
      .valueChanges();
  }

  getYourRequests(uid: string): Observable<PostRequest[]> {
    return this._afs
      .collection<PostRequest>(Collection.Requests, (ref) =>
        ref.where("uid", "==", uid)
      )
      .valueChanges()
      .pipe(
        switchMap((requests: PostRequest[]) => {
          const posts = requests.map((i) => i.postId);

          return combineLatest([
            of(requests),
            combineLatest(
              posts.map((postId) =>
                this._afs
                  .collection<TimePost>(Collection.POSTS)
                  .doc(postId)
                  .valueChanges()
              )
            ),
          ]);
        }),
        map(([Requests, Posts]) => {
          return Requests.map((req: PostRequest) => {
            req.post = Posts.find((a) => a["id"] === req.postId);
            return req;
          });
        })
      );
  }

  async checkLike(id: string, uid: string) {
    let like = await this._afs
      .collection("TimePosts")
      .doc(id)
      .collection("likes")
      .doc(uid)
      .get()
      .pipe(map((i) => i.data()))
      .toPromise();
    if (!like) {
      this._likePost(id, uid);
    } else {
      this._unlikePost(id, uid);
    }
  }

  private async _likePost(id: string, uid: string) {
    await this._afs
      .collection("TimePosts")
      .doc(id)
      .collection("likes")
      .doc(uid)
      .set({ uid: uid, timePostId: id });
    this._afs
      .collection("TimePosts")
      .doc(id)
      .update({ likes: firebase.firestore.FieldValue.increment(1) });
  }

  private async _unlikePost(id: string, uid: string) {
    await this._afs
      .collection("TimePosts")
      .doc(id)
      .collection("likes")
      .doc(uid)
      .delete();
    this._afs
      .collection("TimePosts")
      .doc(id)
      .update({ likes: firebase.firestore.FieldValue.increment(-1) });
  }

  getOwner(uid: string) {
    return this._afs.collection("Users").doc(uid).valueChanges();
  }

  async newRequest(request: PostRequest) {
    const id = this._afs.createId();
    try {
      await this._afs.collection("Requests").doc(id).set(request);
    } catch (err) {
      throw err;
    }
    let ref = this._afs.collection("TimePosts").doc(request.postId);
    return ref.update({ requests: firebase.firestore.FieldValue.increment(1) });

    // const updatePost = this._afs.collection('TimePosts').doc(postId)
  }

  deletePostById(id: string){
    return this._afs.collection("TimePosts").doc(id).delete();
  }
}
