import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GiveThanxAlertComponent } from "./give-thanx-alert/give-thanx-alert.component";
import { GiveThanxComponent } from "./give-thanx/give-thanx.component";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { DirectiveModule } from "../../directives/directive.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Camera } from "@ionic-native/camera/ngx";

@NgModule({
  declarations: [GiveThanxAlertComponent, GiveThanxComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DirectiveModule,
    FontAwesomeModule,
  ],
  providers: [Camera],
})
export class ThanksPromptModule {}
