import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import { Thanks } from '../../../interfaces/Thanks.interface';
import { PostsService } from '../../../services/posts.service';
import { TimePost } from '../../../interfaces/timepost.interface';
import { combineLatest, Observable, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Collection } from '../../../enums/Collections.enum';
import { map, switchMap } from 'rxjs/operators';
import { UserModel } from '../../../interfaces/userModel.interface';
import { UtilService } from '../../../services/util.service';

@Component({
  selector: 'give-thanx-alert',
  templateUrl: './give-thanx-alert.component.html',
  styleUrls: ['./give-thanx-alert.component.scss'],
})
export class GiveThanxAlertComponent implements OnInit {

  faChevronLeft = faChevronLeft;
  thanks:Thanks;
  $post:Observable<TimePost>;
  thanksView:boolean = false;
  constructor(private navParams:NavParams,private _afs:AngularFirestore,private modal:ModalController,public util:UtilService) {
    this.thanks = this.navParams.get('thanks');

    this.$post = this.postById(this.thanks.postId);
  }  

  close(){
    this.modal.dismiss()
  }

  postById(postId:string){
    return this._afs
    .collection<TimePost>(Collection.POSTS)
    .doc(postId)
    .valueChanges()
    .pipe(
      switchMap((posts: TimePost) => {
        const UserUid = posts.uid;
        return combineLatest([
          of(posts),
          this._afs
          .collection<UserModel>(Collection.USERS)
          .doc(UserUid)
          .valueChanges()
        ]);
      }),
      map(([Post, User]) => {
        Post.user = User;
        return Post;
      })
    );
  }

  ngOnInit() {}



}
