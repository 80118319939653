import { UserModel } from "./userModel.interface";

export enum ThanksStatus{
    CREATED = 1,
    THANKED = 2
}
export interface Thanks{
    id?:string
    uid?:string;
    toUid?:string;
    message?:string;
    media?:string[];
    rating?:number;
    postId?:string;
    timeStamp?:any;
    user?:UserModel;
    status?:ThanksStatus;
}