import { Component, NgZone } from "@angular/core";
import { Location } from "@angular/common";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
// import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { AuthService } from "src/app/services/auth.service";
import { Plugins } from "@capacitor/core";
import { Router } from "@angular/router";
import { UtilService } from "./services/util.service";

const { App } = Plugins;
import {
  faSignOutAlt,
  faQuestion,
  faBullhorn,
  faRulerHorizontal,
  faSearch,
  faSearchLocation,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHomeAlt,
  faCommentAltExclamation,
  faCommentLines,
  faHeartSquare,
  faUserAlt,
  faCalendarWeek,
  faQuestionSquare,
  faHeadset,
  faTelescope,
} from "@fortawesome/pro-regular-svg-icons";
import { StringNullableChain } from "lodash";
import { text } from "@fortawesome/fontawesome-svg-core";
import { PostsService } from "./services/posts.service";
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  faUserAlt = faUserAlt;
  faCalendarWeek = faCalendarWeek;
  faQuestionSquare = faQuestionSquare;
  faBullhorn = faBullhorn;
  faHeadset = faHeadset;
  faSignOutAlt = faSignOutAlt;
  faHomeAlt = faHomeAlt;
  faHeartSquare = faHeartSquare;
  faCommentAltExclamation = faCommentAltExclamation;
  faCommentLines = faCommentLines;
  faRulerHorizontal = faRulerHorizontal;
  faTelescope = faTelescope;
  faSearch = faSearch;
  faSearchLocation = faSearchLocation;

  pages = [
    {
      title: "Alerts",
      route: ["main", "notifications"],
      icon: this.faCommentAltExclamation,
    },
    {
      title: "Chat",
      route: ["main", "chat"],
      icon: this.faCommentLines,
    },
    {
      title: "Feed",
      route: ["main", "posts"],
      icon: this.faHeartSquare,
    },
    {
      title: "My Posts",
      route: ["main", "profile"],
      icon: this.faHomeAlt,
    },
    {
      title: "Profile",
      route: ["main", "profile"],
      icon: this.faUserAlt,
    },
    {
      title: "Calendar",
      route: ["main", "calendar"],
      icon: this.faCalendarWeek,
    },
    {
      title: "About Sharetime",
      route: ["main", "about"],
      icon: this.faQuestionSquare,
    },
  ];

  navigationHistory: string;
  previousUrl: string;
  searchRadius: number;
  authenticated;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private zone: NgZone,
    public util: UtilService,
    private router: Router, // private androidPermissions: AndroidPermissions
    public auth: AuthService,
    private location: Location,
    public postService: PostsService
  ) {
    this.auth.$User.subscribe((user) => {
      this.authenticated = !!user;
    });
    this.initializeApp();
    this.searchRadius = this.postService.searchRadius.getValue();
    this.platform.backButton.subscribeWithPriority(10, () => {
      if (this.router.url === "/main/posts") {
        return;
      } else {
        this.location.back();
      }
    });
  }

  rangeChanged(value: number) {
    localStorage.setItem("radius", value.toString());
    this.postService.searchRadius.next(value);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.deepLinks();
    });

    // this.androidPermissions
    //   .checkPermission(this.androidPermissions.PERMISSION.CAMERA)
    //   .then(
    //     (result) => console.log("Has permission?", result.hasPermission),
    //     (err) =>
    //       this.androidPermissions.requestPermission(
    //         this.androidPermissions.PERMISSION.CAMERA
    //       )
    //   );

    // this.androidPermissions.requestPermissions([
    //   this.androidPermissions.PERMISSION.CAMERA,
    //   this.androidPermissions.PERMISSION.GET_ACCOUNTS,
    // ]);
  }

  deepLinks() {
    App.addListener("appUrlOpen", (data: any) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2

        const slug = data.url.split(".love/link/").pop();
        this.util.showToast("slug", slug);
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  signOut() {
    this.auth.signOut();
  }
}
